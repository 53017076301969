import { useTranslations } from '@/hooks/translations';
import { Link, useHistory } from 'react-router-dom';

import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { U11Logo } from '@/components/Image/U11Logo';
import { DropdownBlueLink } from '@/components/Label/DropdownBlueLink';
import { HamburgerButtonDropdown } from '@/components/dropdowns/HamburgerButtonDropdown';
import { withCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';


const UnauthenticatedHeader = ({ isAdmin }) => {
  const text = useTranslations('header');
  const history = useHistory()
  const location = useLocation()


  return (
    <header 
      className={`flex place-content-center w-screen justify-items-end items-center  h-20 sm:h-header z-20 relative`}>
      <div className='flex flex-row justify-between items-center container pl-6 pr-5 xs:pr-0 py-1 sm:py-3'>
              <U11Logo/>
          <div className="flex items-end ">
              <div className='flex flex-row justify-end w-full place-items-center '>
                <div className="mr-10 sm:block hidden align-middle">
                  <Link to="/auth/login" className='flex items-center gap-2'>
                    <UserIcon/>
                    <span className="font-semibold cursor-pointer hover:opacity-80 whitespace-nowrap sm:block hidden place-items-center h-8 mt-2">
                      {text.login}
                    </span>
                  </Link>
                </div>
                <div className="mr-10 sm:block  hidden align-middle ">
                  <Link to="/auth/signup">
                    <BasicBlackButton customSize className={'px-10 py-2.5 font-semibold  rounded-xl'}>{text.signup}</BasicBlackButton>
                  </Link>
                </div>
                <div className="sm:hidden block">
                  <HamburgerButtonDropdown className='xs:mr-3'>
                    <li>
                      <DropdownBlueLink to='/auth/login' >
                        Log in
                      </DropdownBlueLink>
                    </li>
                    <li>
                      <DropdownBlueLink to='/auth/signup'>
                        Sign up
                      </DropdownBlueLink>
                    </li>
                  </HamburgerButtonDropdown>
                </div>

                
              </div>
            </div>
        </div>
    </header>
  );
};


const UserIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="6" r="4" fill="currentColor"/><path fill="currentColor" d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"/></svg>
)


export default withCookies( UnauthenticatedHeader);
