import { cn } from '@/utils/misc';
import React from 'react';


export const PoweredBy = ({className, ...props}) => {
    return (
        <div className={cn('flex gap-2 items-end text-sm w-full justify-center mt-5',className)}>
            Powered by <img className='mb-1' width={60}  src="/assets/images/unity11.png" alt="unity11 logo" />
        </div>
    );
};

