
import usePopup from "@/apis/base/donation/popupStore/usePopup";
import { BasicBackButton } from "@/components/Buttons/BasicBackButton";
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { BasicPickupRequestCard } from "@/components/Cards/BasicPickupRequestCard";
import { EventCarousel } from "@/components/Carousel/EventCarousel";
import { MainContainer } from "@/components/Containers/MainContainer";
import { ManagePopupDialog } from "@/components/Dialog/ManagePopupDialog";
import { LocationIcon } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import { InfoIcon, RequestIcon } from "@/components/Icons";
import { BasicPulseLoader } from "@/components/Loaders/BasicPulseLoader";
import { BasicDropdownTooltip } from "@/components/Tooltips/BasicDropdownTooltip";
import useUsersDependents from "@/hooks/state/admin/useUsersDependents";
import useGetRoleApps from "@/hooks/state/application/useGetRoleApps";
import useCreatePopupRequest from "@/hooks/state/donation/popupStore/useCreatePopupRequest";
import useMyPopupRequests from "@/hooks/state/donation/popupStore/useMyPopupRequests";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { getNiceDateString } from "@/utils";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "../../../../../../../hooks/translations";

const PickupDetails = (props) => {
  const {history, match} = props;
	const { eventid, popupid } = match.params || {};
	const contextQuery = useCurrentOrgContext()
	const ctx = contextQuery.data
  const popupDialogRef = useRef()
  const dependentsQuery = useUsersDependents({userids: [ctx?.userid]})
  const roleRequestQuery = useGetRoleApps({orgid: ctx?.orgid})
	const text = useTranslations("inventoryPickup");
  const [values, setValues] = useState({}); 
  const [hasPickedUp, setHasPickedUp] = useState(false);  
	const popupQuery = usePopup({eventid, popupid});
	const requestQuery = useMyPopupRequests({popupid})
  const requestMutation = useCreatePopupRequest({
    onSuccess: (data) => {
      SuccessToast.fire({text: 'Request Created'})
      requestQuery.refetch()
    },
    onError: (error) => {FailPopup.fire({text: 'Failed to create request'})}
  })
  const popup = popupQuery.data || {};
  
  useEffect(() => {
    const initValues = values
    for (const item of popup?.lineitems || []) {
      if (!initValues[item.id]) {
        initValues[item.id] = {
          shipmentid: item.id,
          quantity: 0,
          confirmedqty: 0
        }
      }
    }
    if (Array.isArray(requestQuery.data) && requestQuery.data?.length > 0) {
      for (const item of requestQuery.data) {
        if (!initValues[item.shipmentid]) initValues[item.shipmentid] = {shipmentid: item.shipmentid, quantity: 0}
        initValues[item.shipmentid].quantity = item.quantity
      }
    } 
    setValues({...values, ...initValues})
  },[requestQuery.data, popupQuery.data])
  
  useEffect(() => {
    checkForPickup()
  },[requestQuery.data])

  function checkForPickup() {
    if (!requestQuery.data) return
    if (Array.isArray(requestQuery.data)) {
      for (const req of requestQuery.data) {
        if (req.qtyconfirmedtime != null) {
          setHasPickedUp(true)
          break
        }
      }
    }
  }

	function handleBack() {
		history.goBack();
	}

  function updateRequest() {
    const params = {
      popupid,
      lineitems: Object.values(values).map((item) => ({
        shipmentid: item.shipmentid,
        quantity: item.quantity
      }))
    }
    requestMutation.mutate(params)
  }

  function handleChange(shipmentid, value) {
    setValues({
      ...values, 
      [shipmentid]: {shipmentid, quantity: value}
    })
  }
  
	const tabs = [text.pickupOverview, text.pickupRequestView, text.pickupProductView];
	return (
  <MainContainer>
		<BasicBackButton onClick={handleBack} className={'absolute'} label={'Back To Events'}/>
		{( popup ? (
			<>
				<div className='w-full form-control items-center'>
					<div className="w-full flex lg:flex-row flex-col-reverse mt-10 lg:mt-0 h-fit items-stretch ">
						<div className="lg:flex-4 pt-10 mr-10  self-stretch form-control ">

							<div>
								<div className="gap-20 w-fit flex whitespace-nowrap text-3xl items-end flex-nowrap justify-between font-semibold">
                  <p>{popup.name}</p>
								</div>
									<EventDates className={'-mt-1'} popup={popup}/>
                  <EventLocation location={popup.location}/>
                  <EventDescription description={popup.description}/>
							</div>
              <div className="w-full h-full flex sm:items-end justify-end sm:flex-row  flex-col gap-2 pt-8 sm:pt-6 ">
                  { requestQuery.isSuccess &&
                    <PickupNumber pickupNumber={ctx?.userid}/>
                  }
                  {popup?.active === '1' && (
                    <UpdateRequestButton disabled={hasPickedUp} onClick={updateRequest}/>
                  )}
                    <UpdateRequestButton disabled={hasPickedUp} onClick={updateRequest}/>
              </div>
              
						</div>
						<EventCarousel eventid={eventid} numImages={1} />
					</div>
          {/* { values && (
            <Catalogue {...{lineItems: popup?.lineitems, values, eventid, onChange: handleChange, maxQuantity: ctx?.dependents?.length + 1, active: popup?.active === '1', hasPickedUp}}/>
          )} */}
				</div>
				<ManagePopupDialog dialogRef={popupDialogRef} initialValues={popup} {...{eventid, orgid: ctx.orgid}}/>
			</>
		) : (
			<BasicPulseLoader/>
		)
		)}
			
		</MainContainer>
	)}

const EventDates = ({className, popup}) => (
	<div className={`flex gap-4 text-xs text-gray-darkSubText w-full max-w-[15rem] my-2 ${className}`}>
		<p>{getNiceDateString(popup.starttime)}</p>
		<p>–</p>
		<p>{getNiceDateString(popup.endtime)}</p>
	</div>
)

const UpdateRequestButton = ({onClick, disabled}) => (
    <BasicRectButton onClick={onClick} disabled={disabled} customSize 
      className={`w-fit  h-10 btn-secondary text-white text-sm font-medium`}>
      Yes, I’d like to attend this event
      <RequestIcon className='w-5 h-5 ml-2'/>
    </BasicRectButton>
)

const EventDescription = ({description}) => (
    <div className='flex relative mt-5'>
        <div className="absolute h-full divider divider-horizontal"></div>
        <p className='text-sm ml-10 text-gray-darkSubText font-medium w-full'>{description}</p>
    </div>
)

const EventLocation = ({location}) => (
	<div className="flex items-center gap-2 text-xs text-gray-darkSubText w-full max-w-[15rem] my-4 ">
		<LocationIcon className='w-6 h-6'/>
		<p>{location}</p>
	</div>
) 

const Catalogue = ({lineItems, values, eventid, onChange, maxQuantity, active, hasPickedUp}) => (
  <div className="w-full min-h-content bg-white flex justify-center">
  <div className="container">
    <div className="w-full h-full ">
      <div className="flex flex-col items-center">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8 my-8 lg:my-12.5">
          { lineItems && (
            Object.values(lineItems || {})
              ?.map?.((item, index) => (
                <div key={lineItems.id}>
                  <BasicPickupRequestCard
                    active={active && !hasPickedUp}
                    eventid={eventid}
                    showRemaining
                    key={item.id}
                    item={item}
                    value={values[item.id]}
                    maxQuantity={maxQuantity}
                    onChange={onChange}/>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  </div>
</div>
)

const PickupNumber = ({pickupNumber}) => (
  <div className="gap-2 w-fit  relative">
    <p className="text-xs absolute -top-5 left-0 right-0 text-gray-subText font-base  text-center">Pick Up</p>
    <p className="flex items-center bg-green-back text-green-text font-semibold rounded-xl  text-center tracking-widest text-2xl px-4 h-[2rem] py-[1.6rem]">
      <span className="font-sans">#</span>
      {pickupNumber} 
      <BasicDropdownTooltip 
        className={'text-sm font-normal text-gray-darkSubText tracking-normal'}
        text={'Show this number to the Event Organizers whenyou arrive !'}
        >
        <InfoIcon className='ml-2 w-[1.2rem] h-[1.2rem] text-green-text ' />
      </BasicDropdownTooltip>
      </p>

     
  </div>
)

export default PickupDetails;
