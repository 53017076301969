import useCheckEmailUnique from '@/hooks/state/auth/useCheckEmaiUnique';
import { validateEmail, validateEmailSuffix } from '@/utils';
import React, { useEffect, useState } from 'react';
import { PhoneInput, TextInput } from '../../components/Inputs';
import { ContinueButton } from './ContinueButton';
import { AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';
import { ErrorLabel } from '@/components/Label/ErrorLabel';


export const ContactRegister = ({onSubmit, suffix, text, ...props}) => {
    const [values, setValues] = useState({
        email: '',
        phonenumber: '',
      });
    const [validations, setValidations] = useState({
        email: '',
        phonenumber: '',
    });
    const [emailDomainIncorrectMessage, setEmailDomainIncorrectMessage] = useState(null);
    const uniqueEmailMutation = useCheckEmailUnique({
      onError: () => setValidations((prevState) => ({...prevState,email: 'has-duplicate'})),
    })
    const handleChange = (prop, value) => {
        setValidations((prevState) => ({ ...prevState, [prop]: '' }));
        setValues((prevState) => ({ ...prevState, [prop]: value }));
    };
    const handleContinue = () => {
            if ( values.phonenumber.length < 10 )
                  setValidations((prevState) => ({...prevState,phonenumber: 'has-error',}));
            else if (values.email === '')
              setValidations((prevState) => ({ ...prevState, email: 'has-empty' }));
            else if (!validateEmail(values.email)) 
              setValidations((prevState) => ({ ...prevState, email: 'has-error' }));
            else if (suffix != null && !validateEmailSuffix(values.email, suffix)) {
                setEmailDomainIncorrectMessage(`Please enter an email that ends with '${suffix}'`);
                setValidations((prevState) => ({...prevState, email: 'incorrect-domain',}));
            } else {
              uniqueEmailMutation.mutate(
                {email: values.email},
                {onSuccess: (data) => onSubmit(values)}
              )
            }
      };
    return (
        <>
            <p className="text-3xl font-semibold">{text.contactInfo}</p>
            <div className="mt-6 w-full">
                <PhoneInput
                    label={text.phone}
                    value={values.phonenumber}
                    onChange={(val) =>
                        handleChange('phonenumber', val.replace(/[^0-9]/g, ''))
                    }
                    error={validations.phonenumber !== ''}
                    errorMessage={text.phoneInvalid}
                />
            </div>
        
            <div className="mt-6 w-full">
              <AuthTextInput
                label={text.email}
                value={values.email}
                onChange={(e) => handleChange('email', e.target.value)}
                errorMessage={
                  validations.email === 'incorrect-domain'
                    ? emailDomainIncorrectMessage
                    : validations.email === 'has-empty'
                    ? 'Please enter an email'
                    : validations.email === 'has-duplicate'
                    ? text.emailNotUnique
                    : validations.email === 'has-error'
                    ? 'Please enter a valid email'
                    : ''
                }
              />
              </div>
              <ContinueButton text={text} handleContinue={handleContinue} />
        </>
    );
};

