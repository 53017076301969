import React, { useEffect, useMemo } from 'react';
import { withCookies } from 'react-cookie';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedHeader from '../../../components/Headers/AuthenticatedHeader/AuthenticatedHeader';
import Footer from '../../../components/Footer';

import routes from '../../../routes';
import { PERMISSION, userTypes } from '../../../constants';
import { useUserContext } from '../../../context/UserContext';
import useMyUserData from '@/hooks/state/useMyUserData';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import OrgHome from '@/views/pages/Main/Home/OrgHome';
import { setRedirectPathCookie } from '@/utils';

function Main(props) {
  const { cookies, history } = props;
  const { data: ctx } = useCurrentOrgContext() || {};
  const { data: userInfo } = useMyUserData() || {};
  const computedRoutes = useMemo(() => getRoutes(),[ctx,userInfo])

  function getRoutes() {
    return routes.map((prop, key) => {
      if (prop.layout === '/') {
        if (prop.admin === true && (!ctx?.isAdmin)) {
          return  <Redirect key={prop.key} from={prop.path} to='/' />
        }
        if ((userInfo) || !prop.auth)
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              {...prop}
            />
          );
        else {
          setRedirectPathCookie()
          return <Redirect key={'auth-redirect'} to="/auth/login"  strict/> ;
        }
      } 
        else if (prop.path === '*') {
          return <Route component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  }

  return (
    <div className="max-w-[100vw] lg:max-w-none  bg-base-200">
      <div className="min-h-screen">
        <Switch>{computedRoutes}</Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withCookies(Main);
