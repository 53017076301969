import { styled } from '@material-ui/core';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';


import { BasicTabs } from '@/components/Tabs/BasicTabs';
import { useAppContext } from '@/context/AppContext';
import { useTranslations } from '@/hooks/translations';
import { CuratorWidget } from '../Home/components/CuratorWidget';
import { GiftIcon, HeartIcon } from '../Home/components/Icons';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';

const AffiliatesList = [
    {
        name: 'Gap',
        url: '/assets/images/logo_gap.png',
    },
    {
        name: 'Zara',
        url: '/assets/images/logo_zara.png',
    },
    {
        name: 'Toronto District School Board',
        url: '/assets/images/logo_tdsb.png',
    },
    {
        name: 'H&M',
        url: '/assets/images/logo_h&m.png',
    },
    {
        name: 'TD Bank',
        url: '/assets/images/logo_tdbank.png',
    },
    {
        name: 'Aritzia',
        url: '/assets/images/logo_aritzia.webp',
    },
    {
        name: 'University of Toronto',
        url: '/assets/images/logo_uoft.png',
    },
    {
        name: 'Aldo',
        url: '/assets/images/logo_aldo.png',
    },
    {
        name: 'CIBC',
        url: '/assets/images/logo_cibc.png',
    },
    {
        name: 'Vancouver School Board',
        url: '/assets/images/logo_vsb.png',
    },
]

export const Welcome = (props) => {
const { cookies, history } = props;
const text = useTranslations('home');
const { setLoading, setMessage } = useAppContext();
const [donationList, setDonationList] = useState([]);
const [brandPreferenceSetupStatus, setBrandPreferenceSetupStatus] =
useState(true);
const tabs = [[text.news, text.donations], [text.news]];
const [shopifyProducts, setShopifyProducts] = useState([]);
const queryParams = new URLSearchParams(window.location.search);
const [splashImageError, setSplashImageError] = useState(false)

const backgroundItems = [
    {
      url: '/assets/images/welcome.jpg',
      text1: 'Welcome to a new',
      text2: 'sustainable marketplace.',
    },
    {
      url: '/assets/images/connecting-brands.jpg',
      text1: 'Connecting brands',
      text2: 'with communities.',
    },
    {
      url: '/assets/images/every-transaction.jpg',
      text1: 'Every transaction',
      text2: 'gives back.',
    },
  ];

    return (
        <div className="w-screen min-h-content bg-primary flex justify-center">
            <div className="container">
                <div className="w-full h-full px-4 sm:px-6 xl:px-8 sm:my-2">
                    <div className="mb-4 w-full xl:flex">
                        <div className="xl:w-3/5 ">
                            <div
                            className="w-full shadow-card rounded-9 relative overflow-hidden select-none"
                            style={{ paddingTop: '65%' }}
                            >
                                <div className="w-full h-full absolute top-0 left-0">
                                    <StyledCarousel
                                        indicatorIconButtonProps={{
                                            style: { color: 'white', opacity: 0.5, padding: 3 },
                                        }}
                                        activeIndicatorIconButtonProps={{
                                            style: { opacity: 1 },
                                        }}
                                        >
                                        {backgroundItems.map((item, i) => (
                                            <div key={i} className="w-full h-full relative">
                                            <img
                                                className="w-full h-full object-cover object-bottom"
                                                src={item.url}
                                                alt={item.text1}
                                            />
                                            <div className="w-full h-full bg-dark absolute top-0 left-0 z-[4] opacity-60" />
                                            <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-[5]">
                                                <div className="flex justify-center items-center text-center">
                                                <div className="flex flex-col justify-center items-center">
                                                    <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                                    {item.text1}
                                                    </p>
                                                    <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                                    {item.text2}
                                                    </p>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                    ))}
                                    </StyledCarousel>
                                </div>
                            </div>
                        </div>
                        <div className="2xl:gap-5 xl:gap-3  gap-3 xl:w-2/5 xl:pl-5 xl:pt-0 pt-6 flex xl:flex-col sm:flex-row flex-col justify-between">
                            {/* TODO Figure out what this is about */}
                            <>
                                <WelcomeCard>
                                <WelcomeCardIcon Icon={GiftIcon}/>
                                    <WelcomeCardTitle>
                                        {/* {text.annualDonated} */}
                                        We activate donation and commerce events to support communities. 
                                    </WelcomeCardTitle>
                                    <WelcomeCardDescription className="2xl:mt-5 mt-3 sm:text-lg text-base font-light">
                                        {/* {text.annualDonation} */}
                                        charities - schools - colleges - universities - unions
                                    </WelcomeCardDescription>
                                {/* <p className='text-xs sm:text-sm text-primary/50'>charities - schools - colleges - universities - unions </p> */}
                                </WelcomeCard>
                                <WelcomeCard>
                                    <WelcomeCardIcon Icon={HeartIcon} />
                                    <WelcomeCardTitle>
                                        {/* {text.communityPartner} */}
                                        When you support your community, <br/> your community supports you. 
                                    </WelcomeCardTitle>
                                    {/* <div className="2xl:mt-5 mt-3 sm:text-lg text-base font-light flex justify-space items-center w-full">
                                        <div className="flex-1">
                                        <p>{text.communitySchools}</p>
                                        <p>{text.schoolText}</p>
                                        <p>{text.CommunityText}</p>
                                        </div>
                                        <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                                        {' > '}
                                        </p>
                                        <div className="flex-1">
                                        <p>{text.communityCharities}</p>
                                        <p>{text.charities}</p>
                                        </div>
                                        <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                                        {' > '}
                                        </p>
                                        <div className="flex-1">
                                        <p>{text.communityBrands}</p>
                                        <p>{text.brands}</p>
                                        </div>
                                    </div> */}
                                    <WelcomeCardDescription>supported by 33 leading brands</WelcomeCardDescription>
                                </WelcomeCard>
                            </>
                            {/*<div className="flex flex-col">*/}
                            {/*  <p className="2xl:mt-5 mt-3 sm:text-base text-sm font-light">{text.communityStore}</p>*/}
                            {/*  <div className="flex justify-start mr-auto">*/}
                            {/*    {shopifyProducts.map(product => (*/}
                            {/*      <a href={product.onlineStoreUrl}>*/}
                            {/*        <img className="h-24" src={product.images[0].src} alt={"product name"}/>*/}
                            {/*      </a>*/}
                            {/*    ))}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                
                    <AffiliatesRow>
                        {AffiliatesList.map((affiliate) => (
                            <AffiliateItem key={affiliate.name} affiliate={affiliate}/>
                        ))}
                    </AffiliatesRow>
                    <div className="mt-4">
                        <BasicTabs tabNames={['News']} activeTab={0} fake>
                            <DefaultCuratorFeed initialRows={4} />
                        </BasicTabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WelcomeCard = ({children}) => (
    <div className="flex-1 flex flex-col justify-evenly items-center text-center rounded-9 bg-base-100 shadow-card p-4 overflow-hidden">
        {children}
    </div>
)

const WelcomeCardIcon = ({Icon}) => (
    <Icon className='sm:text-xl lg: 2xl:w-14 h-auto'/>
)

const WelcomeCardTitle = ({children}) => (
    <p className='2xl:text-2xl text-md xs:text-lg sm:text-xl text-lg mt-4 font-semibold'>
        {children}
    </p>
)
const WelcomeCardDescription = ({children}) => (
    <p className='2xl:mt-5 mt-3  text-sm xs:text-md sm:text-lg'>
        {children}
    </p>
)
const AffiliatesRow = ({children}) => (
    <div className="overflow-x-scroll overflow-y-hidden">
        <div className="xl:w-full w-fit h-20 items-center flex flex-nowrap gap-3 xl:gap-6">
            {children}
        </div>
    </div>
)
const AffiliateItem = ({affiliate}) => (
    <div className='min-w-0'>
        <figure className='h-12 w-20 xl:w-24 xl:h-14 place-content-center'>
            <img className='object-contain w-full h-full' src={affiliate.url} alt={affiliate.name} />
        </figure>
    </div>
)


const StyledCarousel = styled(Carousel)({
    height: '100%',
    '& .CarouselItem': {
      height: '100%',
    },
    '& .CarouselItem div': {
      height: '100%',
    },
    '& > div:last-child': {
      position: 'absolute',
      bottom: 20,
      zIndex: 40,
    },
    '& button svg': {
      fontSize: '17px !important',
    },
  });