import {
  Collections,
  Dashboard,
  Favorite,
  HomeWork,
  People,
  PeopleAlt,
  ShoppingBasket,
  SupervisedUserCircle
} from '@material-ui/icons';

import Organization from '../views/pages/Admin/Organization';
import UIC from '../views/pages/Admin/UIC';
import User from '../views/pages/Admin/User';
import Forgot from '../views/pages/Auth/Forgot';
import Login from '../views/pages/Auth/Login';
import Reset from '../views/pages/Auth/Reset';
import Signup from '../views/pages/Auth/Signup';
import VerifyEmail from '../views/pages/Auth/VerifyEmail';

import Account from '../views/pages/Main/Account';
import Home from '../views/pages/Main/Home';
import MainDonation from '../views/pages/Main/MainDonation';

import Members from '../views/pages/Main/Members';
import News from '../views/pages/Main/News';

import Privacy from '../views/pages/Main/Privacy';
import Terms from '../views/pages/Main/Terms';
import WishlistRequest from '../views/pages/Main/Wishlist';

import { AdminDonation } from '@/views/pages/Admin/BrandDonation';

import BrandSignup from '@/views/pages/Auth/Signup/BrandSignup';
import BrandDonationInventory from '@/views/pages/Brand/Service/Donation/BrandDonationInventory';
import { LocalCommunities } from '@/views/pages/Main/LocalCommunities/LocalCommunities';
import { EventRequest } from '@/views/pages/Main/MainDonation/components/pages/EventRequest';
import PickupOrchestration from '@/views/pages/Main/MainDonation/components/pages/PickupDetails/PickupOrchestration';
import OrgPrefs from '@/views/pages/Main/Organization/OrgPrefs';
import { Welcome } from '@/views/pages/Main/Welcome/Welcome';

import { ChartIcon, ProductsIcon } from '@/components/Icons';
import { BrandPrefReport } from '@/views/pages/Admin/Brand/components/pages/BrandPrefReport';
import { ProductManagement } from '@/views/pages/Admin/ProductManagement/ProductManagement';
import { AdminReportDashboard } from '@/views/pages/Admin/Reports/AdminReportDashboard';
import { AdminWishlistReport } from '@/views/pages/AdminWishlistReport/AdminWishlistReport';
import BrandOrganization from '@/views/pages/Brand/Organization';
import ProcessorOrganization from '@/views/pages/Processor/Organization/ProcessorOrganization';
import ProcessorDonation from '@/views/pages/Processor/Services/Donation';
import ProcessorUser from '@/views/pages/Processor/User';
import BrandManagement from '../views/pages/Admin/Brand';
import BrandDashboard from '../views/pages/Brand/Dashboard';
import BrandDonation from '../views/pages/Brand/Service/Donation';
import BrandDonationLocations from '../views/pages/Brand/Service/Donation/BrandDonationLocations';
import BrandUser from '../views/pages/Brand/User';
import PickupDetails from '../views/pages/Main/MainDonation/components/pages/PickupDetails';
import NotFound from '../views/pages/NotFound';
import DonationShipments from '@/views/pages/Admin/Donation/DonationShipments';
import { BrandDonationSummary } from '@/views/pages/Brand/Service/Donation/BrandDonationSummary/BrandDonationSummary';
import { Debug } from '@/views/pages/Debug/Debug';
import { CommunityRequest } from '@/views/pages/Auth/CommunityRequest';
import { brandDashboard } from '@/constants';

const routes = [
  {
    layout: '/auth',
    path: '/login',
    component: Login,
  },
  {
    layout: '/auth',
    path: '/signup',
    component: Signup,
  },
  {
    layout: '/auth',
    path: '/community_request',
    component: CommunityRequest,
  },
  {
    layout: '/auth',
    path: '/create-org',
    component: BrandSignup,
  },
  {
    layout: '/auth',
    path: '/forgot',
    component: Forgot,
  },
  {
    layout: '/auth',
    path: '/verify-email',
    component: VerifyEmail,
  },
  {
    layout: '/auth',
    path: '/reset-password',
    component: Reset,
  },
  {
    layout: '/',
    path: '/',
    key: 'home',
    auth: false,
    exact: true,
    component: Home,
  },
  {
    layout: '/',
    path: '/welcome',
    auth: false,
    component: Welcome,
  },
  {
    layout: '/',
    path: '/account',
    auth: true,
    component: Account,
  },
  {
    layout: '/',
    path: '/organization-preferences',
    auth: true,
    component: OrgPrefs,
  },
  {
    layout: '/',
    path: '/donation/pickup/:eventid/:popupid/admin',
    auth: true,
    admin:true,
    component: PickupOrchestration,
  },
  {
    layout: '/',
    path: '/donation/:brandorgid/:eventid/:orgid/admin',
    admin: true,
    auth: true,
    component: EventRequest,
  },
  {
    layout: '/',
    path: '/donation/pickup/:eventid/:popupid',
    auth: true,
    component: PickupDetails,
  },
  {
    layout: '/',
    path: '/wishlist',
    admin: true,
    auth: true,
    component: WishlistRequest,
  },
  {
    layout: '/',
    path: '/members',
    // admin: true,
    auth: true,
    component: Members,
  },
  {
    layout: '/',
    path: '/donation',
    auth: true,
    component: MainDonation,
  },
  {
    layout: '/',
    path: '/news',
    auth: true,
    component: News,
  },
  {
    layout: '/',
    path: '/local-communities',
    auth: true,
    component: LocalCommunities,
  },
  {
    layout: '/',
    path: '/terms',
    auth: false,
    component: Terms,
  },
  {
    layout: '/',
    path: '/privacy',
    auth: false,
    component: Privacy,
  },
  {
    layout: '/admin',
    path: '/uic',
    name: 'admin',
    icon: SupervisedUserCircle,
    component: UIC,
  },
  {
    layout: '/admin',
    path: '/debug',
    component: Debug
  },
  {
    layout: '/admin',
    path: '/org',
    name: 'organization',
    icon: HomeWork,
    component: Organization,
  },
  {
    layout: '/admin',
    path: '/user',
    name: 'user',
    icon: People,
    component: User,
  },
  {
    layout: '/admin',
    path: '/brand-management',
    name: 'brand',
    icon: ShoppingBasket,
    component: BrandManagement,
  },
  {
    layout: '/admin',
    path: '/reporting/brand-preferences',
    component: BrandPrefReport
  },   
  {
    layout: '/admin',
    path: '/reporting/wishlist',
    component: AdminWishlistReport,
  },   
  {
    layout: '/admin',
    path: '/reporting',
    name: 'reporting',
    icon: ChartIcon,
    component: AdminReportDashboard,
  },
  {
    layout: '/admin',
    path: '/products',
    name: 'products',
    icon: ProductsIcon,
    component: ProductManagement,
  },
  {
    layout: '/admin',
    path: '/services',
    name: 'services',
    icon: Collections,
    collapse: true,
    children: [
      {
        path: '/donation/inventory/:orgid/:eventid',
        component: BrandDonationInventory,
      },
      {
        path: '/donation/locations/:orgid/:eventid',
        component: BrandDonationLocations,
      },
      {
        path: '/donation/shipments/:orgid/:eventid',
        component: DonationShipments,
      },
      {
        path: '/donation/summary/:orgid/:eventid',
        component: BrandDonationSummary,
      },
      {
        path: '/donation',
        name: 'donation',
        icon: Favorite,
        component: AdminDonation,
      },
      // {
      //   path: '/wishlist',
      //   name: 'donation',
      //   icon: Favorite,
      //   component: Wishlist,
      // },
    ],
  },
  {
    layout: '/brand',
    path: '/dashboard',
    name: 'dashboard',
    icon: Dashboard,
    component: BrandDashboard,
  },
  {
    layout: '/brand',
    path: '/users',
    name: 'users',
    icon: PeopleAlt,
    component: BrandUser,
  },
  {
    layout: '/brand',
    path: '/organizations',
    icon: PeopleAlt,
    component: BrandOrganization,
  },
  {
    layout: '/brand',
    path: '/services',
    name: 'services',
    icon: Collections,
    collapse: true,
    children: [
      {
        path: '/donation/locations/:orgid/:eventid',
        component: BrandDonationLocations,
      },
      {
        path: '/donation/inventory/:orgid/:eventid',
        component: BrandDonationInventory,
      },
      {
        path: '/donation/shipments/:orgid/:eventid',
        component: DonationShipments,
      },
      {
        path: '/donation/summary/:orgid/:eventid',
        component: BrandDonationSummary,
      },
      {
        path: '/donation',
        name: 'donation',
        icon: Favorite,
        component: BrandDonation,
      },  
      // {
      //   path: '/converter',
      //   name: 'converter',
      //   icon: PowerSettingsNew,
      //   component: BrandGoods,
      // },
    ],
  },
  {
    layout: '/processor',
    path: '/dashboard',
    name: 'dashboard',
    icon: Dashboard,
    component: BrandDashboard,
  },
  {
    layout: '/processor',
    path: '/users',
    name: 'users',
    icon: PeopleAlt,
    component: ProcessorUser,
  },
{
    layout: '/processor',
    path: '/organizations',
    name: 'organizations',
    component: ProcessorOrganization,
  },
  {
    layout: '/processor',
    path: '/services',
    name: 'services',
    icon: Collections,
    collapse: true,
    children: [
      {
        path: '/donation/inventory/:orgid/:eventid',
        component: BrandDonationInventory,        
      },
      {
        path: '/donation/locations/:orgid/:eventid',
        component: BrandDonationLocations,        
      },
      {
        path: '/donation/shipments/:orgid/:eventid',
        component: BrandDonationSummary,
      },
      {
        path: '/donation',
        name: 'donation',
        icon: Favorite,
        component: ProcessorDonation,
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
