

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getMyPopupRequests } from '@/apis/base/donation/popupStore/getMyPopupRequests';


export default function useMyPopupRequests({popupid}) {
    const query = useQuery({
        queryKey: ['popup', popupid, ROUTE_IDS.GET_MY_POPUP_REQUESTS],
        queryFn: () => getMyPopupRequests({popupid}),
        enabled: !!popupid,
        notifyOnChangeProps:'all',
        refetchInterval: MILLISEC.MIN,
        refetchIntervalInBackground: true,
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}