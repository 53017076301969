import { OrgDropdown } from '@/components/dropdowns/OrgDropdown';
import React from 'react';
import { BlackButton } from '../../components/Buttons';
import { Link } from 'react-router-dom';
import { OrgSearchBar } from '@/components/Input/OrgSearchBar';


export const JoinOrgPicker = ({text, orgs, initialOrgID, onChange, value, onSubmit, ...props}) => {
    return (
        <>
            <div className="">
              <p className="text-xl md:text-2xl lg:text-3xl font-semibold">Join Your Community</p>
            </div>
            <p className="text-gray-400 text-sm md:text-base">Which community would you like to join?</p>
            <Link to={'community_request'} className='text-sm mt-6 text-blue-button'>Don&apos;t see your community?</Link>
            <OrgSearchBar
              initialOrgID={initialOrgID}
              className='w-full place-self-center !bg-opacity-100 mt'
              orgs={orgs}
              onChange={o =>{console.log('change',o);onChange(o)}}
              placeholder='Search for a community or community organization'
          />
                <a href='https://brandsforcanada.com/' className='text-sm mt-3 text-blue-button '>Learn more</a>        
            <div className="mt-32 w-full">
               <button disabled={!value} onClick={onSubmit} 
                className='bg-[#1D1D1B] disabled:bg-opacity-40 shadow-md text-white w-full rounded-md py-4 px-5 font-medium'
                type='button'
              >
                Continue
              </button>
              <div className="mt-6 w-full flex justify-center">
                <span className="text-gray-400">Already have an account? <Link className="underline" to="/auth/login">{text.login}</Link></span>
              </div>
            </div>
        </>
    );
};

