
import React, { useEffect, useState } from "react";


import { BasicBoxTabs } from "@/components/Input/BasicBoxTabs";
import MultiSelectDropdown from "@/components/Input/MultiSelectDropdown";
import { PageTitle } from "@/components/Label/PageTitle";
import { OrgWishlistTable } from "@/components/Tables/OrgWishlistTable/OrgWishlistTable";
import { WISHLIST_VIEW_TYPE } from "@/constants";
import useUsersDependents from "@/hooks/state/admin/useUsersDependents";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useWishlistFilters } from "@/hooks/state/wishlist/helpers/useWishlistFilters";
import { useWishlistObject } from "@/hooks/state/wishlist/helpers/useWishlistObject";
import useModOrgWishlistReport from "@/hooks/state/wishlist/useModOrgWishlistReport";
import useMyWishlist from "@/hooks/state/wishlist/useMyWishlist";
import useOrgWishlist from "@/hooks/state/wishlist/useOrgWishlist";
import useOrgWishlistReport from "@/hooks/state/wishlist/useOrgWishlistReport";
import useSubmitMyWishlist from "@/hooks/state/wishlist/useSubmitMyWishlist";
import useWishProductCategories from "@/hooks/state/wishlist/useWishProductCats";
import useWishProducts from "@/hooks/state/wishlist/useWishProducts";
import useWishTargetCust from "@/hooks/state/wishlist/useWishTargetCust";
import useWishlistTree from "@/hooks/state/wishlist/useWishlistTree";
import { useTranslations } from "@/hooks/translations";
import { objectFromForm } from "@/utils";
import { WishlistGrid } from "@/views/pages/Admin/ProductManagement/components/WishlistGrid";
import { Popup } from "@/wrappers/swal/Popup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { queryResultFns } from "@/wrappers/swal/queryResultFns";
import { HelpOutlineOutlined } from '@material-ui/icons';
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import config from "@/apis/config";
import ExportToExcel from "@/components/ExportToExcel";
import { downloadCSV } from "@/utils/fileUtils/downloadCSV";

const MAX_QUANTITY = 999999

const Wishlist = ({history}) => {
  const text = useTranslations("wishlist");
  const [showGridView, setShowGridView] = useState(false);
  // const [filters, setFilters] = useState({})

  const contextQuery = useCurrentOrgContext({})
  const ctx = contextQuery.data

  const categoryQuery = useWishProductCategories({})
  const dependentsQuery = useUsersDependents({userids: [ctx?.userid]})
  const aggregateWishlistQuery = useOrgWishlist({orgID: ctx?.isAdmin ? ctx?.orgid : null})
  const wishlistReportQuery = useOrgWishlistReport({orgid: ctx?.isAdmin ? ctx?.orgid : null})
  const demographicsQuery = useWishTargetCust({})
  const myWishlistQuery = useMyWishlist({})
  const wishlistTree = useWishlistTree({})
  const wishlistProductsQuery = useWishProducts({})

  const {
    demoFilter, 
    catFilter,
    handleCatFilterChange,
    handleDemoFilterChange,
} = useWishlistFilters(demographicsQuery.data, categoryQuery.data?.flat, true)
  
  const wishlistObj = useWishlistObject({
    view: ctx?.isAdmin ? WISHLIST_VIEW_TYPE.ORG_ADMIN : WISHLIST_VIEW_TYPE.MEMBER,
    memberWishlistObj: myWishlistQuery.data,
    communityWishlistObj:  aggregateWishlistQuery.data,
    orgWishlistReportObj: wishlistReportQuery.data,
    wishProductsData: wishlistProductsQuery.data,
  })
  
  const wishlistReportMutation = useModOrgWishlistReport(queryResultFns('Successfully submitted wishlist report','failed to submit wishlist report'))
  const wishlistMutation = useSubmitMyWishlist({
    onSuccess: (data) => SuccessToast.fire({text: "Your wishlist has been submitted",}),
    onError: (data) => {
      Popup.fire({
        title: "Error",
        text: "Failed to submit wishlist",
        icon: "error",
        position: "center",
        showCancelButton: true
      })
    }
  })

  const [maxQuantity, setMaxQuantity] = useState(0)

  useEffect(() => {
    if (ctx?.finStatus !== "Approved" && !ctx?.isAdmin) 
      history.push('/')
    setMaxQuantity(
      ctx?.isAdmin ? 
        MAX_QUANTITY 
        : (dependentsQuery.data?.uniqueDependents?.length || 0) + 1)
  },[dependentsQuery.data, ctx])

  function submitWishlistForm() {
    const form = document?.forms?.wishlistForm
    form?.requestSubmit?.()
  }

  const handleSubmitWishlist = (e) => {
    e.preventDefault()
    const form = objectFromForm(e.target, true)
    const wishlistitems =   
      Object.entries(form).map(([key, value]) => ({
          productid: key,
          quantity: value
      }))
    if (wishlistitems.length === 0) return
    if (ctx.isAdmin) wishlistReportMutation.mutate({orgid: ctx.orgid, wishlistitems})
    else wishlistMutation.mutate({wishlistitems})
  }

  function exportWishlist() {
    if (!wishlistTree.data) return
    const amalgamated = wishlistTree.data.map((superCat) => {
      if (superCat.name === 'Clothing') {
        return superCat.subnodes.map(subCat => {
          return subCat.products.map(product => ({
            category: `Clothing: ${subCat.name}`,
            ...product
          }))
        }).flat()
      } else {
        return superCat.products.map(product => ({
          category: superCat.name,
          ...product
        }))
      }
    })
    const flatList = amalgamated.flat()
    const headers = ['category','name','targetname','description',]
    const rows = flatList.map((product) => [product.category, product.name, product.targetname, product.description])
    downloadCSV('wishlist',headers, rows)
  }
  
  return (
    <div className="w-full min-h-content bg-white flex justify-center py-6">
      <div className="w-full h-full px-2 py-4 2md::px-6 xl:px-8 ">
        <PageTitle compact className={'items-center'}>
          {text.title}
          <a
            href='https://unitedinchange.gitbook.io/uic-end-user-guide/'
            className="flex justify-start font-normal text-lg pb-1 ml-2"
            target="_blank" rel="noreferrer">
            <span className="lg:block hidden text-blue-cornflower">{text.endUserGuide}</span>
            <div className="block lg:hidden mb-px"><HelpOutlineOutlined fontSize="medium"/></div>
          </a>
              <div className="ml-auto">
                {ctx?.isAdmin && (
                    <BasicBoxTabs
                      tabNames={[text.tileView, text.tableView]}
                      activeTab={showGridView ? 1 : 0}
                      onChange={(index) => setShowGridView(index === 1)}
                      // tabClassName="tabs"
                      className=" tabs-xs sm:tabs-sm md:tabs-md font-normal mb-2"
                    />
                )}
                <div role="button" className={'btn btn-secondary btn-md text-md px-8 sm:px-11 md:px-14'} onClick={submitWishlistForm}>
                    {ctx?.isAdmin ? text.submitReport : text.updateRequest}
                </div>
              </div>
        </PageTitle>
          <div className="pb-5">
        
            <div>
              <p className="max-w-[55rem] leading-1.33">Submission will confirm your Family and Community’s needs. This information is used to request products from leading brands. Unless you have registered dependants, each member can only request up to 1 item per category. Please update every 3 months, for in season items. </p>
              <p className="leading-normal text-red-strong mt-1">{text.productListedBelow}</p>
            </div>
          </div>

          { !ctx.isAdmin && (
              <WishlistMax maxQuantity={maxQuantity}/>
          )}
          <div className={`flex flex-col md:flex-row  gap-0.5 md:gap-2  w-full  ${showGridView ? 'items-start justify-start md:items-center' : 'items-end justify-end md:items-center'}`}>
            <MultiSelectDropdown
                placeHolder='Filter by Category'
                options={categoryQuery.data?.tree}
                renderFn={(val) => val?.name}
                onToggle={handleCatFilterChange}
                selectedIDs={catFilter}
                className='w-44'
            />
            <MultiSelectDropdown
                placeHolder='Filter by Audience'
                options={demographicsQuery.data}
                renderFn={(val) => val?.name}
                onToggle={handleDemoFilterChange}
                selectedIDs={demoFilter}
                className='w-44'
            />
          </div>
        <form id='wishlistForm' onSubmit={handleSubmitWishlist}>
            {showGridView ? (
              <div className="mt-2">
                <OrgWishlistTable
                  wishlistObj={wishlistObj}
                  data={wishlistTree.data}
                  maxQuantity={maxQuantity}
                  demoFilter={demoFilter}
                  catFilter={catFilter}
                />
              </div>
            ) : (
              <WishlistGrid 
                {...{
                  demoFilter,
                  catFilter,
                  wishlistObj,
                  aggregateWishlistQuery,
                  maxQuantity,
                  wishlistTree,
                  showControls:false,
                  view: ctx?.isAdmin ? WISHLIST_VIEW_TYPE.ORG_ADMIN : WISHLIST_VIEW_TYPE.MEMBER,
                }}
              />
            )}
        </form>

      </div>
    </div>
  );
};



const WishlistMax = ({maxQuantity}) => (
  <div className="sticky h-fit top-4 w-full form-control items-end right-0 z-[12] mb-4">
    <div className=" px-6 py-4 form-control rounded-2xl bg-white block  shadow-all-light w-[20rem]">
      Your <span className="font-semibold">Family In Need</span> application entitles you to up to <span className="font-semibold">{maxQuantity}</span> of an item
    </div>
  </div>
)

export default Wishlist;