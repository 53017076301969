import { cn } from '@/utils/misc';
import React from 'react';

export const U11Logo = ({className, ...props}) => {
    return (
        <img 
            className={cn('w-24 sm:w-32  rounded-md',className)} 
            src='/assets/images/unity11.png' 
            alt='Unity11 logo' 
            {...props}
            />
    );
};
