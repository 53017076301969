import { useEffect, useState } from 'react';
import { useCookies, withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { PasswordInput, TextInput } from './components/Inputs';

import useLogin from '@/hooks/state/auth/useLogin';
import useSendVerificationEmail from '@/hooks/state/auth/useSendVerificationEmail';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useMyUserData from '@/hooks/state/useMyUserData';
import { prefetchContextQueries } from '@/utils/contextUtils';
import { preloadOrgImages } from '@/utils/imageUtils';
import { removeContextQueries } from '@/utils/queryUtils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { InfoToast } from '@/wrappers/swal/InfoToast';
import { Popup } from '@/wrappers/swal/Popup';
import { useQueryClient } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { PERMISSION } from '../../../constants';
import { useTranslations } from '../../../hooks/translations';
import { getCookie, validateEmail } from '../../../utils';
import { ErrorAlert } from '@/components/alerts/ErrorAlert';
import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';
import { PoweredBy } from '@/views/pages/Auth/components/PoweredBy';
import { AuthPasswordInput, AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';
import { red } from '@material-ui/core/colors';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';

function Login(props) {
  const { history, location } = props;
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [validations, setValidations] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'refreshToken']);
  const text = useTranslations('login');
  
  const client = useQueryClient();
  const orgContextQuery = useCurrentOrgContext();
  const verificationEmailMutation = useSendVerificationEmail({
    onSuccess: (data) => {InfoToast.fire({text:'Verification email sent'})},
    onError: (err) => {FailPopup.fire({text: 'Failed to send verification email'})}
  })
  
  const loginMutation = useLogin({
    onSuccess: handleLoginSuccess,
    onError: handleLoginFail,
  });

  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const showSignupSuccessMessage = params.get('signup_success')
  const urlEmail = params.get('email')

  useEffect(() => {
    if (showSignupSuccessMessage === 'true') {
      SuccessToast.fire('Welcome to Unity11','Please Sign in to get started')

    }
    if (urlEmail != null) {
      handleChange('email', urlEmail.replaceAll('"',""))
    }
  },[code, urlEmail])
  console.log("showSignupSuccessMessage", showSignupSuccessMessage)
  


  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: '' }));
    setValues({ ...values, [prop]: value });
  };

  async function handleLoginSuccess(data) {
      const { token, refreshtoken } = data || {};
      client.getQueryCache().clear()
      // client.resetQueries({predicate:() => true})
      // client.invalidateQueries({queryKey: ['context']})
      removeContextQueries(client)
      setCookie('token', token, { path: '/'});
      setCookie('refreshToken', refreshtoken, { path: '/' });
      setLoginSuccess(true)
      prefetchContextQueries(client, setCookie)
        .then((data) => {
          const redirectPath = getCookie('redirect')
          if (redirectPath) {
            removeCookie('redirect', { path: '/'});
            history.push(redirectPath)
          } else {
            history.push('/')
          }

          setLoginSuccess(true)
        });
  }
  function handleLoginFail(err) {
    setError('Incorrect email or password')
  }

  function resendVerificationEml() {
    verificationEmailMutation.mutate({email: values.email})
  }

  const checkValidations = () => {
    if (values.email === '') {
      setValidations({ email: 'has-empty', password: '' });
      return false;
    } else if (!validateEmail(values.email)) {
      setValidations({ email: 'has-danger', password: '' });
      return false;
    } else if (values.password === '') {
      setValidations({ email: '', password: 'has-empty' });
      return false;
    } else if (values.password.length < 6) {
      setValidations({ email: '', password: 'has-danger' });
      return false;
    } else {
      setValidations({ email: '', password: '' });
    }
    return true;
  };

  const handleLogin = () => {
    if (!checkValidations()) return;
    removeCookie('orgIdContext', { path: '/',});
    if (code) {
      Popup.fire({
        title: 'Confirm Join Community',
        text: 'Are you sure you want to add this community to your account?',
        showCancelButton: true,
        showConfirmButton: true
      }).then((result) => {
        const invitecode = result.isConfirmed ? code : null
        loginMutation.mutate({invitecode, ...values})
        })  
    } else {
      loginMutation.mutate({invitecode: null, ...values});}
  };
  // const handleReroute = () => {
  //   if (!userInfoQuery.isSuccess  || (code != '' && code != null)) return;
  //   if (userInfoQuery?.data?.['admin level'] === PERMISSION.UIC_ADMIN)
  //     history.push('/admin');
  //   // else if (orgContext?.['org type id'] === OrgTypes.BRANDS)
  //   //   history.push('/brand');
  //   else history.push('/');
  // };
  return (
    <form className="sm:w-3/5 w-4/5" >
      <div className="flex w-full gap-5 ">
        <p className="text-3xl font-semibold whitespace-nowrap">{text.login}</p>
        {error && (
          <ErrorAlert error={error} severity="error" onClose={() => setError(null)}>
            {error}
            { error === text.emailNotVerified &&
              <p className='underline tracking-wide hover:cursor-pointer' onClick={resendVerificationEml}>
                Resend verification Email?
              </p>
            }
          </ErrorAlert>
        )}
      </div>
      <div className="mt-9 w-full">
      </div>
      <div className="mt-9 w-full">
        <AuthTextInput
          autoComplete="email"
          type='email'
          label={text.email}
          value={values.email}
          onChange={(e) => handleChange('email', e.target.value)}
          errorMessage={
            validations.email === 'has-empty'
              ? text.fieldRequired
              : validations.email === 'has-danger'
              ? text.emailInvalid
              : ''
          }
        />
      </div>

      <div className="mt-6 w-full">
        <AuthPasswordInput
          autoComplete="current-password"
          type='password'
          name='password'
          label={text.password}
          value={values.password}
          onChange={(e) => handleChange('password', e.target.value)}
          onKeyUp={(e) => e.keyCode === 13 && handleLogin()}
          errorMessage={
            validations.password === 'has-empty'
              ? text.fieldRequired
              : validations.password === 'has-danger'
              ? text.passwordInvalid
              : ''
          }
        />
      </div>
      <div className="w-full flex justify-end">
        <Link className="text-gray-400 underline" to="/auth/forgot">
          {text.forgotPassword}
        </Link>
      </div>
      <div className="mt-9 w-full">
        <BasicBlackButton
          variant="contained"
          size="large"
          type='button'
          className="w-full"
          onClick={() => handleLogin()}
        >
          Continue
        </BasicBlackButton>
      </div>
      <div className="mt-6 w-full flex justify-center">
        <span className="text-gray-400">{text.noAccount} <Link className="underline" to="/auth/signup">{text.signup}</Link></span>
      </div>
      <div className="mt-12 w-full">
        <PoweredBy/>
      </div>
    </form>
  );
}

export default withCookies(Login);
