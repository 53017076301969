import { ROUTE_IDS } from '@/apis/base/routeId';
import { XIcon } from '@/components/Icons';
import { BasicFileInput } from '@/components/Input/BasicFileInput';
import { BasicImageDropdown } from '@/components/dropdowns/BasicImageDropdown';
import { IMAGE_TYPE } from '@/constants';
import useDeleteImage from '@/hooks/state/image/useDeleteImage';
import { filesToDataUri, getWishlistUrls } from '@/utils';
import { FailToast } from '@/wrappers/swal/FailToast';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const ImageUploadAndAuditor = forwardRef(ImageUploadAndAuditorFn)
    function ImageUploadAndAuditorFn({
    preexistingImages,
    subjectNodeID,
    subjectImageType=null,
    imageRef,
    label=null,
    name='file',
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    disabled=false,
    ...props
    }, ref) {
    const [images, setImages] = useState([]);
    const deleteImageMutation  = useDeleteImage({
        onSuccess: () => SuccessToast.fire('Image deleted'),
        onError: handleImageDeleteFail
    })
    console.log(subjectNodeID);
    useEffect(loadPreexistingImages,[preexistingImages])

    useImperativeHandle(ref,() => ({
        resetImages: () => setImages([])
    }))

    function loadPreexistingImages() {
        if (!preexistingImages || !subjectNodeID || !subjectImageType) return
        if (preexistingImages) {
            setImages(getWishlistUrls(preexistingImages))
        }
    }
    
	const handleImageUpload = (e) => {
		let files  = Array.from(imageRef?.current?.files);
		if (!Array.isArray(files) || files?.length < 1)  return
		if (files.length > 6)  {
			files = files.slice(0,6);
		}	
		filesToDataUri(files)
            .then(data => setImages(prev => [...prev, ...data]))
	}
    const handleRemoveAttach = (id, img) => {
        if (img?.length < 100) {
            const target = preexistingImages?.find((pre) => img?.includes?.(pre?.name))
            if (target) {
                deleteImageMutation.mutate({
                    imageid: target.id,
                    queryKeyToInvalidate: getInvalidationQueryKeyFromImageType(subjectImageType)
                })
            }  else  return handleImageDeleteFail()
        }
		const dt = new DataTransfer()
		const refFiles = imageRef.current.files
		// Update file URI's
		let list = images;
		list.splice(id, 1);
		if (imageRef?.current?.value && !list.length) {
			imageRef.current.value = null;
		}
		// Update input ref
		for (let i = 0; i < refFiles?.length; i++) {
			if (id != i)
				dt.items.add(refFiles[i])
		}
		let updatedImages = [...list];
        setImages(updatedImages);
		imageRef.current.files = dt.files;
		// updatedImages.splice(id, 1);
	};

    function handleImageDeleteFail() {
        FailToast.fire('Failed to delete image')
    }

    function handleImageLoadFail(e) {
        e.currentTarget.parentNode?.parentNode?.classList.add('hidden')
    }

    return (
        <Fragment>
            <div className={'flex items-end gap-2 mt-4 '}>
                { images.map((img, idx) => (
                    <div
                        key={idx}
                         className="indicator">
                        <div 
                            className="indicator-item indicator-end " 
                            onClick={() => {handleRemoveAttach(idx, img)}}
                        >
                            <div className="btn btn-circle btn-xs btn-primary ">
                                <XIcon />
                            </div>
                        </div>
                        <BasicImageDropdown 
                            disableTracking
                            src={img} 
                            imageProps={{
                                onError: handleImageLoadFail
                            }}
                            imageClassName='w-auto h-fit object-cover aspect max-h-28'
                            className={`
                                w-auto dropdown-top
                                ${idx < 3 ? ` dropdown-start ` : ` dropdown-end `}
                                `}
                            dropdownClassName={`
                                w-72  
                                `}
                        />
                        {/* <img src={img} alt={`event ${idx}`} /> */}
                    </div>
                ))

                }
            </div>
            <BasicFileInput 
                containerClassName={containerClassName}
                onChange={handleImageUpload}
                ref={imageRef}
                accept="image/*"
                label='Images'
                className="text-center text-gray-450 text-sm file-input-secondary"
                
                required={required}
                id="contained-button-file"	      
            />
        </Fragment>
    );
}


function getInvalidationQueryKeyFromImageType(imageType) {
    switch (imageType) {
        case IMAGE_TYPE.PRODUCT:
            return 'wishlist'
        case IMAGE_TYPE.USER:
            return 'user'
        case IMAGE_TYPE.EVENT:
            return 'bd'
        case IMAGE_TYPE.EVENT_INVENTORY:
            return 'bd'
        default:
            return null
    }
}