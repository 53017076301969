import { orgRoleAppQueryConfig } from "@/hooks/state/application/useGetRoleApps";
import { appStatusEnumQueryConfig } from "@/hooks/state/enum/useAppStatusEnum";
import { roleEnumQueryConfig } from "@/hooks/state/enum/useRoleEnum";
import { constructContextData, orgInfoQueryConfig } from "@/hooks/state/organization/useCurrentOrgContext";
import { myUserDataQueryConfig } from "@/hooks/state/useMyUserData";
import { getCookie } from "@/utils";
import { fetchQueries } from "@/utils/queryUtils";

export function checkForNeedsRedirect(contextQuery, history) {
    const context = contextQuery?.data
    if (!context || contextQuery?.isPending) {console.log('context not available yet');return}
    const {pathname} = history.location || {}
    const rootPath = pathname?.split('/')?.[1]
    switch (rootPath) {
        case 'wishlist':
            if (!context.isAdmin && !context.isUICAdmin) history.replace('/')
            break
        case '':
            if (context?.isUICContext) {console.log('isUICContext'); history.replace('/admin')}
            else if (context?.brand) {console.log('isBrand'); history.replace('/brand')}
            else if (context?.processor) {console.log('isProcessor'); history.replace('/processor')}
            break;
        case 'admin':
            if (!context?.isUICContext) {console.log('not an admin'); history.replace('/')}
            break;
        case 'processor':
            if (!context?.processor) {console.log('not an processor'); history.replace('/')}
            break;
        case 'brand':
            if (!context?.brand) {console.log('not an brand'); history.replace('/')}
            break;
        case 'corporation':
            if (!context?.corporation) {console.log('not an corporation'); history.replace('/')}
            break;
        default:
            console.log('no redirect needed');
            break;
    }
  }

  export function prefetchContextQueries(client, setCookie, orgid) {
      if (!client) return
      return new Promise((resolve, reject) => {
        fetchQueries(client, [
            roleEnumQueryConfig(), 
            appStatusEnumQueryConfig(), 
            myUserDataQueryConfig()
        ]).then(([rEnum, aData, uData]) => {
            console.log("rEnum", rEnum)
            const targetOrg = orgid || uData?.defaultorg
            if (!getCookie('orgIdContext' === targetOrg)) {
                if (!setCookie) 
                    reject('need setCookie function to set orgIdContext cookie during context prefetch')
                setCookie('orgIdContext', targetOrg, {path: '/'})
            }
            const roleArray = rEnum.map((r) => r.id)
            fetchQueries(client, [
                orgInfoQueryConfig(targetOrg),
                orgRoleAppQueryConfig({roleids: roleArray, orgid: targetOrg}),
            ])
            .then(([oData,rData]) => {
                const ctxData = constructContextData(uData, rData, oData, aData)
                client.setQueryData(
                    ['context','org', orgid], 
                    ctxData)
                resolve(ctxData)
            })
            
            // client.fetchQuery(contextQueryComponentConfigs(rData, targetOrg))
            //     .then(resolve)
        })
    })

  }