import React, { useEffect, useMemo } from 'react';
import { BasicDialog } from './BasicDialog';
import { TableSkeleton } from '../Skeletons/TableSkeleton';
import { arrayToObject, closeDialogWithRef } from '@/utils';
import { ConfirmDeliveryIcon } from '../Icons';
import useConfirmPopupPickup from '@/hooks/state/donation/popupStore/useConfirmPopupPickup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { ConfirmPopupPickupTable } from '../Tables/SimpleTable/ConfirmPopupPickupTable';


export const ConfirmPopupPickupDialog = ({dialogRef, request, popupState, popupid, eventid, ...props}) => {
    const [values, setValues] = React.useState({})
    const confirmMutation = useConfirmPopupPickup({
        onSuccess: (data) => {
            SuccessToast.fire({text: 'Confirmed Pickup'})
            closeDialogWithRef(dialogRef)
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to Update Request'})}
    })

    useEffect(() => {
      if (!request) return
      
      const confirmedArr = request.items.map(item => ({
        shipmentid: item.shipmentid, qtyconfirmed: item.quantity, inventoryid: item.inventoryid, productname: item.productname
    }))
      const confirmedObj = arrayToObject(confirmedArr, 'shipmentid')
       setValues(confirmedObj)
    }, [request])
        
    function onChange(changedValue) {
        console.log('change', changedValue);
        console.log(values);
        setValues({
            ...values,
            ...changedValue
        })
        console.log(values);
    }

    function onSubmit() {
        const items = Object.values(values)?.map(({shipmentid, qtyconfirmed}) => ({shipmentid, qtyconfirmed: qtyconfirmed}))
        
        const params = {popupid, userid: request.userid, lineitems: items}
        confirmMutation.mutate(params)
    }

    
    return  (
        <BasicDialog
            ref={dialogRef}
            id='shipmentForm'
            title="Confirm Pickup Quantities "
            className='w-fit h-fit min-h-[30rem] overflow-y-scroll pb-20'
            hideActions
            {...props}
        >   
        {values && request?.items?.length > 0 ? (
            <>
            { values &&
                <ConfirmPopupPickupTable
                    values={Object.values(values)}
                    eventid={eventid}
                    onChange={onChange}
                    {...props}
            />
            }
                <div className="my-4 flex w-full justify-end">
                    
                    <button className="btn btn-secondary " onClick={onSubmit}>
                        Confirm Pickup
                        <ConfirmDeliveryIcon className='w-5 h-5'/>
                    </button>
                

                    <button 
                        className="btn btn-neutral ml-2" 
                        onClick={() => closeDialogWithRef(dialogRef)}
                    >
                        Cancel
                    </button>
                </div>
            </>
        ) : (
            <div className='form-control items-center w-full gap-14'>
                <div className="skeleton rounded-xl w-1/3 aspect-video"/>
                <TableSkeleton numRows={3} rowHeightClass='h-18'/>
            </div>
        )}
        </BasicDialog>
    ) 
};



