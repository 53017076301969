import { useEffect, useMemo, useState } from "react";
const defaultBreakpoints = {
    xs: 370,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
}

const useBreakpoints = (breakpoints=defaultBreakpoints) => {
    const sortedBreakPoints = useMemo(() => 
        Object.entries(breakpoints).sort(compareBreakpointEntries),
        [breakpoints])

    const [breakpoint, setBreakPoint] = useState(getCurrentBreakPoint());
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
     }, []);

    function handleResize() {
        setBreakPoint(getCurrentBreakPoint())
    }

    function getCurrentBreakPoint() {
        for (const [key, bp] of sortedBreakPoints) {
            if (window.innerWidth > bp) {
                return key
            }
        }
        return sortedBreakPoints.slice(-1)[0][0]
    }
    // console.log('current breakpoint', breakpoint);
    return breakpoint
}
function compareBreakpointEntries(a, b) {
    if (a[1] > b[1]) return -1
    if (a[1] === b[1]) return 0
    if (a[1] < b[1]) return 1
}

export default useBreakpoints