import useBreakpoints from '@/hooks/utils/useBreakPoints';
import useDefaultCuratorFeed from '@/hooks/utils/useDefaultCuratorFeed';
import { openDialogWithRef } from '@/utils';
import { CuratorCard } from '@/views/pages/Main/Home/components/Curator/CuratorCard';
import { CuratorGridContainer } from '@/views/pages/Main/Home/components/Curator/CuratorGridContainer';
import { SelectedPostDialog } from '@/views/pages/Main/Home/components/Curator/SelectedPostDialog';
import React, { useEffect, useMemo } from 'react';


const DEFAULT_NUM_COLS = 5
export const DefaultCuratorFeed = ({initialRows=1, showLoadMore=false, ...props}) => {
    const [visibleRows, setVisibleRows] = React.useState(initialRows)
    const [selectedPost, setSelectedPost] = React.useState(null)
    const [numColumns, setNumColumns] = React.useState(DEFAULT_NUM_COLS)
    const breakpoint = useBreakpoints()
    const q = useDefaultCuratorFeed()
    useEffect(() => {
        switch (breakpoint) {
            case 'sm':
            case 'xs':
                setNumColumns(2)
                break
            case 'md':
                setNumColumns(3)
                break
            case 'lg':
                setNumColumns(4)
                break
            case 'xl':
                setNumColumns(5)
                break
            default:
                return
        }
    },[breakpoint])

    useEffect(() => {
        if (visibleRows === initialRows) return
        setVisibleRows(initialRows)
    },[initialRows])

    function loadMore() {
        if (q.data?.posts?.length <= visibleRows * numColumns) return
        setVisibleRows(prev => prev + 1)
    }

    function handleDialogClose() {
        setSelectedPost(null)
    }

    function selectPost(post) {
        setSelectedPost(post)
        document.getElementById('curatorDialog')?.showModal?.()
    }


    function selectNextPost() {
        const idx = q.data?.posts?.findIndex(post => post.id === selectedPost.id)
        if (idx === -1)  
            return
        const nextIndex = idx + 1
        if (nextIndex >= q.data?.posts?.length)
            selectPost(q.data?.posts[0])
        else
            selectPost(q.data?.posts[nextIndex])
    }

    function selectPrevPost() {
        const idx = q.data?.posts?.findIndex(post => post.id === selectedPost.id)
        if (idx === -1) 
            return
        const prevIndex = idx - 1
        if (prevIndex < 0)
            selectPost(q.data?.posts[q.data?.posts?.length - 1])    
        else
            selectPost(q.data?.posts[prevIndex])
    }

    return q.data?.posts && (
        <div className='flex w-full items-center'>
            <CuratorGridContainer visibleRows={visibleRows} numColumns={numColumns}>
                {q.data?.posts?.slice?.(0,5*visibleRows)?.map((post, i) => <CuratorCard key={post?.id} post={post} selectPost={selectPost}/>)}
            </CuratorGridContainer>
            { showLoadMore && !q.isPending &&  (q.data?.posts?.length > visibleRows * numColumns) && (
                <button className="btn btn-primary px-16 text-lg mb-4 md:mt-2 lg:mt-3 xl:mt-4" onClick={loadMore}>
                    Load More
                </button>
            )}
            <SelectedPostDialog
                selectedPost={selectedPost}
                onClose={handleDialogClose}
                selectNextPost={selectNextPost}
                selectPrevPost={selectPrevPost}
                />
        </div>
    );
};

